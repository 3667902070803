
@import "../../bootstrap/variables.less";

@body-bg:					#fff;

@text-color:				#000;
@link-color:				blue;
@link-hover-color:			#000;
@link-hover-decoration:		underline;

@font-family-base:			arial,sans-serif;
@font-size-base:			10px;
@line-height-base:			1.2;
@font-size-h1:				56px;
@headings-small-color:		#000;

@pager-color:					blue;
@pager-color-active:			#000;
@pager-padding:					2px;
@pager-border:					none;
@pager-text-decoration:			underline;
@pager-font-weight-active:		normal;
@pager-text-decoration-active:	none;
@pager-hover-bg:				transparent;
@pager-font-size:				24px;
@pager-font-size-mobile:		24px;

@thumb-width:				492px;
@thumb-height:				368px;
@thumb-margin-top:			1px;
@thumb-margin-right:		1px;
@thumb-margin-bottom:		1px;
@thumb-margin-left:			1px;
@thumb-border-width:		2px;
@thumb-border:				2px solid #000;
@thumb-hover-border:		2px solid #fff;

@zindex-popup:				100;
@tooltip-arrow-width:		5px;
@tooltip-bg:				#444;
@popup-text-color:			#fff;
@popup-font-weight:			bold;

@social-links-margin:				20px 0 15px;

@sites-list-font-size:				20px;
@sites-list-color:					#000;
@sites-list-title-margin-bottom:	20px;
@sites-list-title-size:				27px;
@sites-list-title-weight:			bold;
@sites-list-title-decoration:		none;

@sites-footer-traffictrade-size:	18px;
@sites-footer-traffictrade-margin:	0 0 20px;
@sites-footer-margin:				20px;
@sites-footer-size:					16px;
@sites-footer-color:				@text-color;

@import "common/bootstrap.less";
@import "../../common/utils.less";
@import "../../common/flags-small.less";
@import "../../common/flags.less";
@import "common/modules.less";
@import "common/sites_list.less";
@import "common/sites_footer.less";
@import "common/popup.less";
@import "common/mozaique_simple.less";
@import "common/pagination.less";

a {
	text-decoration: underline;
	cursor: pointer;
}
.red {
	color: #f00;
}
h1 {
	text-align: center;
	margin: 0 auto 5px;
	padding: 2px;
	max-width: 960px;
	img {
		float: left;
	}
	small {
		font-size: 26px;
		line-height: 30px;
		display: block;
		overflow: hidden;
		padding-left: 10px;
	}
}
h2 {
	text-align: center;
	font-size: 20px;
	line-height: 24px;
	margin: 0;
}
.links {
	font-size: 18px;
	line-height: 24px;
	text-align: center;
	clear: both;
}
#language-switcher {
	position: absolute;
	right: 10px;
	top: 0;
}
#sites-list {
	margin: 20px auto;
}
.outro {
	font-size: 26px;
	margin: 4px 0;
	text-align: center;
	&.big {
		font-size: 30px;
	}
	&.small {
		font-size: 24px;
		margin: 24px 0;
	}
}
@media(max-width: 768px) {
	h1 {
		img {
			float: none;
		}
		small {
			padding: 5px;
		}
	}
}